import React, { useState } from "react";
import "./TicTacToe.css";

import VanillaTilt from "vanilla-tilt";
import { useEffect } from "react";
import Block from "../Block/Block";
import BtnSound from "../../Assets/dot.mp3";
import ResetSound from "../../Assets/cross.mp3";
import AlertSound from "../../Assets/alert.mp3";

const TicTacToe = () => {
  const [gameStatus, setGameStatus] = useState("ongoing");
  const [winnerMessage, setWinnerMessage] = useState("");
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".Block"), {
      max: 2,
      speed: 10,
      glare: true,
      "max-glare": 0.5,
    });
  }, []); // Empty dependency array to ensure it runs once
  return (
    <div className="container">
      <div className="board">
        <Board />
      </div>
      {gameStatus === "win" && (
        <div>
          <p className="winner">{winnerMessage}</p>
        </div>
      )}
    </div>
  );

  function Board({ dot, cross }) {
    const [marks, setMarks] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [player, setPlayer] = useState(1);

    //   const reset = () => {
    //     const [marks, setMarks] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    //   };

    useEffect(() => {
      const combination = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
      ];

      for (let c of combination) {
        if (marks[c[0]] === 1 && marks[c[1]] === 1 && marks[c[2]] === 1) {
          setGameStatus("win");
          setWinnerMessage("Player 1 Wins!");
          console.log("Player 1 Wins");
        }
        if (marks[c[0]] === 2 && marks[c[1]] === 2 && marks[c[2]] === 2) {
          setGameStatus("win");
          setWinnerMessage("Player 2 Wins!");
          console.log("Player 2 Wins");
        }
      }
    }, [marks]);

    function play() {
      new Audio(BtnSound).play();
    }
    function ResetPlay() {
      new Audio(ResetSound).play();
    }

    const playAlert = () => {
      const audio = new Audio(AlertSound);

      // Set up an event listener for the 'ended' event
      audio.addEventListener("ended", () => {
        alert("Please Click on Another Block");
      });

      audio.play();
    };

    const changeMark = (i) => {
      const m = [...marks];
      if (m[i] === 0) {
        m[i] = player;
        setMarks(m);
        setPlayer(player === 1 ? 2 : 1);
        play();
      } else {
        playAlert();
      }
    };

    const reset = () => {
      setMarks([0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setPlayer(1);
      setGameStatus("ongoing");
      setWinnerMessage("");
      ResetPlay();
    };
    return (
      <div className="board">
        <div>
          <Block changeMark={changeMark} mark={marks[0]} position={0}></Block>
          <Block changeMark={changeMark} mark={marks[1]} position={1}></Block>
          <Block changeMark={changeMark} mark={marks[2]} position={2}></Block>
        </div>
        <div>
          <Block changeMark={changeMark} mark={marks[3]} position={3}></Block>
          <Block changeMark={changeMark} mark={marks[4]} position={4}></Block>
          <Block changeMark={changeMark} mark={marks[5]} position={5}></Block>
        </div>
        <div>
          <Block changeMark={changeMark} mark={marks[6]} position={6}></Block>
          <Block changeMark={changeMark} mark={marks[7]} position={7}></Block>
          <Block changeMark={changeMark} mark={marks[8]} position={8}></Block>
        </div>
        <button className="reset" onClick={reset}>
          Reset
        </button>
      </div>
    );
  }
};
export default TicTacToe;
