function Block({ mark, changeMark, position }) {
  return (
    <div className="imgContainer">
      <div
        className={`Block mark${mark}`}
        onClick={(e) => changeMark(position)}
      ></div>
    </div>
  );
}

export default Block;
